import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-7 col-sm-12" }
const _hoisted_3 = { class: "card mb-3" }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = { class: "menu-item px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServicePaymentForm = _resolveComponent("ServicePaymentForm")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_ServicePaymentCard = _resolveComponent("ServicePaymentCard")!
  const _component_Cards = _resolveComponent("Cards")!
  const _component_ServicePaymentSummary = _resolveComponent("ServicePaymentSummary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_modal, {
      id: "servicePayment",
      name: _ctx.state.modalName,
      ref: "servicePaymentRef"
    }, {
      default: _withCtx(() => [
        (_ctx.caze.id)
          ? (_openBlock(), _createBlock(_component_ServicePaymentForm, {
              key: 0,
              id: _ctx.state.id,
              payment: _ctx.state.payment,
              "company-id": _ctx.caze.client.id,
              onSaved: _ctx.saved
            }, null, 8, ["id", "payment", "company-id", "onSaved"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, "Payments (" + _toDisplayString(_ctx.page?.data.total) + ")", 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_QuickAction, { size: "w-150px" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", {
                      class: "menu-link px-3",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
                    }, " Add Payment ")
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createVNode(_component_Cards, {
          total: _ctx.page.data.total,
          "card-data": _ctx.page.data.data,
          "current-page": _ctx.filter.paginationPage,
          "rows-per-page": _ctx.filter.paginationSize,
          onCurrentChange: _ctx.handleUpdatePage,
          stretched: ""
        }, {
          "card-info": _withCtx(({card: view}) => [
            _createVNode(_component_ServicePaymentCard, {
              payment: view,
              onEdit: _ctx.onEdit,
              onDelete: _ctx.onDelete
            }, null, 8, ["payment", "onEdit", "onDelete"])
          ]),
          _: 1
        }, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"])
      ]),
      _createVNode(_component_ServicePaymentSummary, {
        "caze-id": _ctx.cazeId,
        ref: "servicePaymentSummary"
      }, null, 8, ["caze-id"])
    ])
  ], 64))
}