
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Cards from "@/components/base/common/Cards.vue";
import ServicePaymentCard from "@/views/service/payment/ServicePaymentCard.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ServicePaymentForm from "@/views/service/payment/ServicePaymentForm.vue";
import Swal from "sweetalert2";
import ServicePaymentService from "@/core/services/ServicePaymentService";
import ServicePaymentSummary from "@/views/service/payment/ServicePaymentSummary.vue";

export default defineComponent({
  name: "List",
  components: {ServicePaymentSummary, ServicePaymentForm, BaseModal, ServicePaymentCard, Cards, QuickAction},
  props: {
    cazeId: {}
  },
  setup(props) {
    const objectFilter = ref({cazeId: props.cazeId});
    const page = computed(() => store.state.ServicePaymentModule.page);
    const caze = computed(() => store.state.CaseModule.case);
    const state = ref<any>({
      id: '',
      payment: {
        number: '',
        status: 'PENDING',
        method: 'NA',
        serviceIdRequest: '',
        cazeIdRequest: '',
        currency: 'USD',
        amount: 0
      },
      modalName: 'New Payment'
    })
    return {
      ...LoadFilterObjects(Actions.LOAD_PAYMENT_SERVICES, objectFilter.value, ['service']),
      page,
      state,
      caze,
      objectFilter
    }
  },
  methods: {
    saved() {
      this.paginationLoad();
      const modal = this.$refs.servicePaymentRef as typeof BaseModal;
      modal.hideBaseModal();
      const overview = this.$refs.servicePaymentSummary as typeof ServicePaymentSummary;
      overview.load(this.cazeId);
    },
    onAdd() {
      this.state = {
        id: '',
        payment: {
          number: '',
          status: 'PENDING',
          method: 'NA',
          serviceIdRequest: '',
          cazeIdRequest: this.cazeId,
          currency: 'USD',
          receiveDate: null,
          amount: 0
        },
        modalName: 'New Payment'
      }
      const modal = this.$refs.servicePaymentRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onEdit(payment: any) {
      this.state = {
        id: payment.id,
        payment: {
          number: payment.number,
          status: payment.status,
          method: payment.method,
          serviceIdRequest: payment.service.id,
          cazeIdRequest: this.cazeId,
          currency: payment.currency,
          receiveDate: payment.receiveDate,
          amount: payment.amount,
        },
        modalName: 'Edit Payment'
      }
      const modal = this.$refs.servicePaymentRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ServicePaymentService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }
})
