
import {defineComponent, ref, watch} from 'vue'
import ServicePaymentService from "@/core/services/ServicePaymentService";
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";

export default defineComponent({
  name: "ServicePaymentSummary",
  components: {CurrencyFormat, Row},
  props: {
    cazeId: {}
  },
  setup(props) {
    const data = ref<any>({pending: 0, receivable: 0, received: 0})
    const load = (id) => {
      ServicePaymentService.overview(id).then(res => {
        data.value = res;
      })
    }
    load(props.cazeId);
    watch(() => props.cazeId, cb => {
      load(cb)
    })
    return {
      data,
      load
    }
  }
})
