
import {defineComponent} from 'vue'
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import ServicePaymentStatus from "@/views/service/payment/ServicePaymentStatus.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import MenuLink from "@/components/base/action/MenuLink.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ServicePaymentCard",
  components: { MenuLink, QuickAction, ServicePaymentStatus, CurrencyFormat, Row},
  props: {
    payment: {}
  },
  emits: ['edit', 'delete'],
  setup() {
    return {
      ...LoadPanel(),
    }
  },
  methods: {
    onEdit(payment) {
      this.$emit('edit', payment);
    },
    onDelete(id) {
      this.$emit('delete', id);
    }
  }
})
