
import {computed, defineComponent, ref, watch} from 'vue'
import {ServiceSelect} from "@/core/composite/composite";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {PAYMENT_METHOD, SERVICE_PAYMENT_STATUS} from "@/core/config/Constant";
import store from "@/store";
import ServicePaymentService from "@/core/services/ServicePaymentService";
import {Field} from "vee-validate";

export default defineComponent({
  name: "ServicePaymentForm",
  components: {BaseSelect, DateTimeSelect, BaseForm, BaseRadio, Field},
  props: {
    companyId: {},
    id: {},
    payment: {type: Object, require: true},
  },
  emits: ['saved'],
  setup(props) {
    const model = ref<any>(props.payment);
    watch(() => props.payment, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    return {
      currencies,
      submitting,
      model,
      SERVICE_PAYMENT_STATUS,
      PAYMENT_METHOD,
      ...ServiceSelect(props.companyId)
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      if (this.id) {

        ServicePaymentService.update(this.id, this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        ServicePaymentService.create(this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false;
        })
      }
    },
    onServiceSelect(id) {
      const s = this.services.find(item => item.id === id)
      if (s) {
        this.model.amount = s.value;
      }
    }
  }
})
